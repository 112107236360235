import { Select as AntSelect, SelectProps } from 'antd';
import { FC, useId } from 'react';
import { ErrorMessage } from '../_common/ErrorMessage';
import { Label } from '../_common/Label/Label';

interface Props extends SelectProps<any, any> {
	label?: string;
	error?: string;
}

export const Select: FC<Props> = props => {
	const {
		label, //
		error,
		status,
		className,
		...rest
	} = props;

	const id = useId();

	return (
		<div className={className}>
			{label && (
				<Label
					id={id}
					label={label}
				/>
			)}

			<AntSelect
				{...rest}
				style={{ width: '100%' }}
				id={id}
				status={error ? 'error' : status}
				popupMatchSelectWidth={false}
			/>

			{error && <ErrorMessage errorMessage={error} />}
		</div>
	);
};
