import cn from 'classnames';
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import s from './Tabs.module.scss';

interface Props<T> extends ComponentPropsWithoutRef<'div'> {
	selectedTab: T;
	tabs: T[];
	selectTab: (tab: T) => void;
}

export const Tabs = <T extends string | number>(props: PropsWithChildren<Props<T>>) => {
	const {
		selectedTab, //
		tabs,
		selectTab,
		className,
	} = props;

	return (
		<div className={cn(s.container, className)}>
			{tabs.map(tab => (
				<div
					className={cn(s.tab, tab === selectedTab && s.tab_active)}
					key={tab}
					onClick={() => selectTab(tab)}
				>
					{tab}
				</div>
			))}
		</div>
	);
};
