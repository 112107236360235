// Checks if the link has http:// in before the url if not it adds it.
export const checkIfLink = (url: string) => {
	if (url.slice(0, 7) === 'http://') {
		return url;
	} else if (url.slice(0, 8) === 'https://') {
		return url;
	} else {
		return 'https://' + url;
	}
};
