import { Input } from 'antd';
import { PasswordProps } from 'antd/es/input';
import { FC, useId } from 'react';
import { ErrorMessage } from '../_common/ErrorMessage';
import { Label } from '../_common/Label/Label';

interface Props extends PasswordProps {
	label?: string;
	error?: string;
}

const { Password: AntPasswordInput } = Input;

export const PasswordInput: FC<Props> = props => {
	const {
		label, //
		error,
		status,
		className,
		...rest
	} = props;

	const id = useId();

	// * Render
	return (
		<div className={className}>
			{label && (
				<Label
					id={id}
					label={label}
				/>
			)}

			<AntPasswordInput
				{...rest}
				id={id}
				status={error ? 'error' : status}
			/>

			{error && <ErrorMessage errorMessage={error} />}
		</div>
	);
};
