import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { SourceType } from 'src/pages/MergeToolPage/_BLL/copper_menu/types';
import { MergeToolMenu } from 'src/pages/MergeToolPage/ui/CopperMode/MergeToolMenu';
import { CompanyMergeForm } from 'src/pages/MergeToolPage/ui/SilverMode/CompanyMergeForm';
import { RequestStatus } from 'src/shared/api/types';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import s from './CopperMode.module.scss';

interface Props {
	sources: [SourceType, SourceType];
	dictionaries: Dictionaries;
}

export const CopperMode: FC<Props> = props => {
	const {
		sources, //
		dictionaries,
	} = props;

	const companyConflicts = useAppSelector(state => state.mergeToolDataPoints.companyConflicts);
	const status = useAppSelector(state => state.mergeToolDataPoints.status);
	const selectedCompany = useAppSelector(state => state.copperMenu.selectedCompany);

	// * Render parts
	const renderContent = () => {
		if (status === RequestStatus.loading) {
			return (
				<div className={s.preloaderWrapper}>
					<Preloader />
				</div>
			);
		}

		if (status === RequestStatus.still) {
			if (companyConflicts && selectedCompany) {
				return (
					<CompanyMergeForm
						sources={sources}
						targetCompany={selectedCompany}
						companyConflicts={companyConflicts}
						dictionaries={dictionaries}
					/>
				);
			} else {
				return <></>;
			}
		}
	};

	// * Render
	return (
		<div className={s.wrapper}>
			<MergeToolMenu sources={sources} />

			{renderContent()}
		</div>
	);
};
