import { FC, useEffect, useState } from 'react';
import { PageDecorator } from 'src/app/decorators';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { actionsPeopleDuplicates } from 'src/pages/PeopleDuplicatesPage/_BLL/slice';
import { BooleanRequest } from 'src/pages/QcAutomationPage/_BLL/types';
import { SingleStringFilter } from 'src/pages/QcAutomationPage/ui/SingleStringFilter';
import { RequestStatus } from 'src/shared/api/types';
import { Pagination, PaginationI } from 'src/shared/ui/Pagination';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import s from './PeopleDuplicatesPage.module.scss';
import { dictionaryIdToString } from 'src/app/redux/slices/metaDictionaries/lib';

export const PeopleDuplicatesPage: FC = () => {
	// * Selectors
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);
	const companies = useAppSelector(state => state.peopleDuplicates.companies);
	const total = useAppSelector(state => state.peopleDuplicates.total);
	const status = useAppSelector(state => state.peopleDuplicates.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { callPeopleDuplicates, autoMergePeopleDuplicates } = actionsPeopleDuplicates;

	// * Pagination
	const initialPagination = {
		pageSize: 10,
		pageCount: total ? Math.ceil(total / 10) : 0,
		pageIndex: 0,
	};

	const [currentPagination, setCurrentPagination] = useState<PaginationI>(initialPagination);

	useEffect(() => {
		total &&
			setCurrentPagination(prevState => ({
				...prevState,
				pageCount: Math.ceil(total / 10),
			}));
	}, [total]);

	// * Filters
	// - Company
	const [companyNameSubstring, setCompanyNameSubstring] = useState<string | null>(null);

	const setCompanySubstring = (value: string | null) => {
		setCompanyNameSubstring(value && value !== '' ? value : null);
	};

	// - Payload
	const getExpression = () => {
		const activeFilters = [companyNameSubstring].filter(filter => filter !== null);
		return activeFilters ? activeFilters.map((item, index) => index).join(' AND ') : '';
	};

	const search: BooleanRequest = {
		expression: getExpression(),
		filters: [],
	};

	if (companyNameSubstring) {
		search.filters.push({
			datapointId: 1000001, // legal name
			filters: [
				{
					operator: 'like',
					values: [companyNameSubstring],
				},
			],
		});
	}

	const applyFilters = () => {
		setCurrentPagination(initialPagination); // Will trigger, reset pagination. Which will trigger request useEffect below, that include the filters.
	};

	// * Initialize/pagination
	useEffect(() => {
		if (search.filters.length === 0) {
			dispatch(
				callPeopleDuplicates({
					params: {
						page_index: currentPagination.pageIndex,
						page_size: currentPagination.pageSize,
					},
				}),
			);
		} else {
			dispatch(
				callPeopleDuplicates({
					params: {
						page_index: currentPagination.pageIndex,
						page_size: currentPagination.pageSize,
					},
					payload: {
						search,
					},
				}),
			);
		}
	}, [currentPagination]);

	// * Auto merge
	const onAutoMergeClick = (companyId: number, personKey: string) => {
		dispatch(
			autoMergePeopleDuplicates({
				companyId,
				personKey,
				pagination: currentPagination,
			}),
		);
	};

	// * Render
	return (
		<PageDecorator>
			<Pagination
				className={s.pagination}
				pageSize={currentPagination.pageSize}
				pageCount={currentPagination.pageCount}
				currentPage={currentPagination.pageIndex}
				changePage={setCurrentPagination}
				visibleOptionsCount={5}
				hasPortionChange={false}
			/>

			<div className={s.filterList}>
				<SingleStringFilter
					label="Company name"
					value={companyNameSubstring}
					onChange={setCompanySubstring}
				/>

				<ButtonPrimary onClick={applyFilters}>Apply filters</ButtonPrimary>
			</div>

			{status === RequestStatus.loading ? (
				<Preloader />
			) : (
				<div className={s.companyList}>
					{companies &&
						dictionaries &&
						companies.map(company => (
							<div
								className={s.card}
								key={company.companyId}
							>
								{/* TODO: Create separate component */}
								<div className={s.infoRow}>
									<div>
										<div className={s.caption}>Company Id:</div>
										{company.companyId}
									</div>
									<div>
										<div className={s.caption}>Company Name:</div>
										{company.companyName}
									</div>
									<div>
										<div className={s.caption}>Has Conflicts:</div>
										{company.hasConflicts ? 'Yes' : 'No'}
									</div>
									<div>
										<div className={s.caption}>Is Merged Values Taken From Different People:</div>
										{company.isMergedValuesTakenFromDifferentPeople ? 'Yes' : 'No'}
									</div>
								</div>

								{/* * People list */}
								<div className={s.infoColumn}>
									{company.people.map(person => (
										<div
											className={s.person}
											key={person.key}
										>
											{/* TODO: Create separate component */}
											<div className={s.infoRow}>
												<div>
													<div className={s.caption}>Person Name:</div>
													{person.key}
												</div>
												<div>
													<div className={s.caption}>Has Conflicts:</div>
													{person.hasConflicts ? 'Yes' : 'No'}
												</div>
												<div>
													<div className={s.caption}>Is Merged Values Taken From Different People:</div>
													{person.isMergedValuesTakenFromDifferentPeople ? 'Yes' : 'No'}
												</div>
											</div>

											{/* * Properties */}
											<div className={s.infoColumn}>
												{person.properties.map(property => (
													<>
														{property.hasConflicts && (
															<div
																className={s.property}
																key={property.internalName}
															>
																<div className={s.displayName}>{property.displayName}</div>

																<div>
																	{property.datapoints.map(datapoint => (
																		<div key={datapoint.datapointId}>
																			{datapoint.values.map(value => (
																				<div key={value.personID}>{datapoint.dictionary ? dictionaryIdToString(value.value, dictionaries[datapoint.dictionary]) : value.value}</div>
																			))}
																		</div>
																	))}
																</div>
															</div>
														)}
													</>
												))}

												{person.hasConflicts ? (
													<a
														href={`/dashboard/${company.companyId}`}
														target="_blank"
														rel="noreferrer"
													>
														<ButtonSecondary>{`Open company: ${company.companyId} in dashboard`}</ButtonSecondary>
													</a>
												) : (
													<ButtonPrimary onClick={() => onAutoMergeClick(company.companyId, person.key)}>Auto merge</ButtonPrimary>
												)}
											</div>
										</div>
									))}
								</div>
							</div>
						))}
				</div>
			)}
		</PageDecorator>
	);
};
