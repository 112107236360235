import cn from 'classnames';
import { FC } from 'react';
import { InfoTooltip } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/__company/ui/InfoTooltip';
import { PersonMergeMeta } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { PersonField } from 'src/pages/MergeToolPage/ui/SilverMode/PeopleMergeForm/PersonField/PersonField/PersonField';
import s from './ConflictTypeColumn.module.scss';

interface Props {
	personMergeMeta: PersonMergeMeta[];
}

export const ConflictTypeColumn: FC<Props> = props => {
	const {
		personMergeMeta, //
	} = props;

	// * Sorting
	const getAccepted = (personMeta: PersonMergeMeta) => {
		return !!(personMeta.acceptedBy && personMeta.acceptedAtUTC);
	};

	const restPeople = personMergeMeta.filter(personMeta => !getAccepted(personMeta));
	const acceptedPeopleMeta = personMergeMeta.filter(personMeta => getAccepted(personMeta));

	// * Render
	return (
		<div className={cn(s.paperCustom, s[`paperCustom_${personMergeMeta[0].conflictType}`])}>
			<div className={s.columnHeader}>
				<div className={s.title}>{personMergeMeta[0].conflictType} </div>
				<InfoTooltip title={personMergeMeta[0].conflictTypeDescriptions} />
			</div>

			<div className={s.people}>
				{restPeople.map(personMeta => (
					<PersonField
						key={personMeta.name}
						personMeta={personMeta}
					/>
				))}

				{acceptedPeopleMeta.map(personMeta => (
					<PersonField
						key={personMeta.name}
						personMeta={personMeta}
					/>
				))}
			</div>
		</div>
	);
};
