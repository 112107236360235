import { DataPoint, DataPointStatus } from '../_BLL/data_points/types';
import { Dictionaries } from '../../../app/redux/slices/metaDictionaries/types';
import { StatusType } from 'src/pages/DashboardPage/_BLL/dashboard/types/types';

// * Form options text render functions
const valueToReadableString = (value: string, status: DataPointStatus, options: [string, string] | null) => {
	if (value === 'true') {
		return options ? options[1] : 'Yes';
	} else if (value === 'false') {
		return options ? options[0] : 'no';
	} else if (value === '' && status === StatusType.EMPTY) return StatusType.EMPTY;
	else if (value === '' && status === StatusType.UNKNOWN) return 'unavailable';
	else return value;
};

const getDataPointPrefix = (dataPointName: string, value: string) => {
	let prefix: null | string = null;

	if (dataPointName.includes('Target')) {
		if (dataPointName.includes('Exists')) {
			prefix = 'EXISTS';
		} else if (dataPointName.includes('Date') || dataPointName.includes('Deadline')) {
			prefix = 'DEADLINE';
		} else {
			prefix = 'PERCENTAGE';
		}
	}

	if (dataPointName.includes('MeanPayGap')) {
		if (dataPointName.includes('Delta')) {
			prefix = 'DELTA';
		} else {
			prefix = 'PERCENTAGE';
		}
	}

	if (dataPointName === 'CompDEIIndustry') prefix = 'INDUSTRIES';
	if (dataPointName === 'CompDEISector') prefix = 'SECTORS';

	return prefix ? `<b>${prefix}</b>: ${value}` : value;
};

const valueToString = (value: string | null, status: DataPointStatus, dictionaryName: string | null, dictionaries: Dictionaries, options: [string, string] | null) => {
	if (value !== null && value !== '') {
		let valuesArray = value
			.split(';')
			.map(value => `${valueToReadableString(value, status, options)}`)
			.sort();

		if (dictionaryName) {
			// @ts-ignore
			valuesArray = valuesArray.map(value => dictionaries[dictionaryName].find(dictionaryItem => +value === dictionaryItem.id).name);
		}

		return valuesArray.join('\n');
	} else if (status === StatusType.UNKNOWN) {
		return 'unavailable';
	} else {
		return 'empty';
	}
};

export const dataPointsToString = (dataPoints: DataPoint[], dictionaries: Dictionaries): string => {
	const arrayOfDataPoints = dataPoints.map(dataPoint => {
		const value = valueToString(dataPoint.value, dataPoint.status, dataPoint.dictionary, dictionaries, dataPoint.options);
		return getDataPointPrefix(dataPoint.datapointName, value);
	});

	return arrayOfDataPoints.join('\n');
};
