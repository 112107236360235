import { combineReducers } from 'redux';

import { slice as kpi } from 'src/pages/MainPage/_BLL/kpi/slice';

import { slice as copperMenu } from '../../pages/MergeToolPage/_BLL/copper_menu/slice';
import { slice as mergeToolDataPoints } from '../../pages/MergeToolPage/_BLL/data_points/slice';
import { slice as mergeToolCompanySearch } from '../../pages/MergeToolPage/_BLL/silver_conflicts/slice';

import { slice as qcAutomation } from 'src/pages/QcAutomationPage/_BLL/slice';

import { slice as peopleDuplicates } from 'src/pages/PeopleDuplicatesPage/_BLL/slice';

import { slice as mainSearch } from '../../pages/MainPage/_BLL/main_search/slice';
import { slice as mainSearchResults } from '../../pages/MainSearchResultsPage/_BLL/slice';

import { slice as notifications } from '../providers/NotificationsProvider/_BLL/notifications/slice';

import { slice as auth } from '../../pages/AuthPage/_BLL/slice';
import { slice as metaDictionaries } from './slices/metaDictionaries/slice';

import { slice as dump } from 'src/features/DumpCompany/_BLL/slice';
import { slice as admin } from 'src/pages/AdminPage/_BLL/slice';
import { slice as dashboardCompany } from '../../pages/DashboardPage/_BLL/company/slice';
import { slice as dashboardMain } from '../../pages/DashboardPage/_BLL/dashboard/slice';
import { slice as dashboardPeople } from '../../pages/DashboardPage/_BLL/people/slice';
import { slice as uploadToGold } from '../../pages/DashboardPage/_BLL/uploadToGold/slice';
import { slice as documents } from 'src/pages/DashboardPage/_BLL/documents/slice';

const dashboard = {
	dashboardMain: dashboardMain.reducer,
	dashboardPeople: dashboardPeople.reducer,
	dashboardCompany: dashboardCompany.reducer,
	uploadToGold: uploadToGold.reducer,
	documents: documents.reducer,
};

const mergeTool = {
	copperMenu: copperMenu.reducer,
	mergeToolDataPoints: mergeToolDataPoints.reducer,
	mergeToolCompanySearch: mergeToolCompanySearch.reducer,
};

export const rootReducer = combineReducers({
	notifications: notifications.reducer,
	// auth, // SAGA
	auth: auth.reducer,
	// metaDictionaries, // SAGA
	metaDictionaries: metaDictionaries.reducer,
	...dashboard,

	mainSearch: mainSearch.reducer,
	mainSearchResults: mainSearchResults.reducer,
	admin: admin.reducer,
	dump: dump.reducer,

	// mainSearch,
	kpi: kpi.reducer,
	// mainSearchResults,
	// ...dashboard,
	...mergeTool,
	qcAutomation: qcAutomation.reducer,
	peopleDuplicates: peopleDuplicates.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
