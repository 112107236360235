import { PeopleSavePayload, SinglePerson } from './types';
import { PaginationI } from 'src/shared/ui/Pagination';
import { RequestStatus } from 'src/shared/api/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProofContent } from '../dashboard/types/types';
import { rtkApiRequest } from 'src/shared/api/api';
import { actionsDashboard } from '../dashboard/slice';
import { actionsNotifications } from '../../../../app/providers/NotificationsProvider/_BLL/notifications/slice';
import { RootState } from 'src/app/redux/rootReducer';
import { getPeopleWithUpdatedSource, SourceInfo } from './lib/getPeopleWithUpdatedSource';
import { updateCompanyInfo } from './lib/updateCompanyInfo';
import { removeDuplicates } from 'src/shared/lib/object_array';

const NAME = 'dashboard_people';

// * Thunks
const personSave = createAsyncThunk(`${NAME}/personSave`, async (payload: { people: SinglePerson; proofContents: { [key: string]: ProofContent } | null }, thunkAPI) => {
	const { dispatch, getState } = thunkAPI;
	const { people, proofContents } = payload;
	const state = getState() as RootState;
	const peoplePagination = state.dashboardPeople.peoplePagination;
	const companyId = state.dashboardCompany.currentCompany?.id;
	const activePeriodId = state.dashboardMain.activePeriodId;

	const body = {
		...people,
		proofContents, // ! Is not a required property for a successful fetch.
	};

	const res = await rtkApiRequest.rtkPOSTRequest<SinglePerson[]>({
		url: 'people',
		payload: body,
		thunkAPI,
	});

	dispatch(actionsDashboardPeople.storePerson({ peopleUpdated: res, peoplePagination }));
	updateCompanyInfo(dispatch, companyId, activePeriodId);
	dispatch(actionsDashboard.toggleEditMode(null));
	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'Executive/board saved successfully',
		}),
	);
});

const personCreate = createAsyncThunk(`${NAME}/personCreate`, async (payload: { personData: SinglePerson }, thunkAPI) => {
	const { dispatch, getState } = thunkAPI;
	const { personData } = payload;

	const state = getState() as RootState;
	const peoplePagination = state.dashboardPeople.peoplePagination;
	const companyId = state.dashboardCompany.currentCompany?.id;
	const activePeriodId = state.dashboardMain.activePeriodId;

	const res = await rtkApiRequest.rtkPOSTRequest<SinglePerson[]>({
		url: 'people',
		payload: personData,
		thunkAPI,
	});

	dispatch(actionsDashboardPeople.storePerson({ newPerson: res, peoplePagination }));
	updateCompanyInfo(dispatch, companyId, activePeriodId);
	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'New person created successfully',
		}),
	);
});

const peopleDelete = createAsyncThunk(`${NAME}/peopleDelete`, async (payload: { peopleIds: string }, thunkAPI) => {
	const { dispatch, getState } = thunkAPI;
	const { peopleIds } = payload;
	const state = getState() as RootState;
	const peoplePagination = state.dashboardPeople.peoplePagination;
	const companyId = state.dashboardCompany.currentCompany?.id;
	const activePeriodId = state.dashboardMain.activePeriodId;

	const res = await rtkApiRequest.rtkDELRequest<{ id: number }[] | { id: number }>({
		url: 'people',
		params: {
			ids: peopleIds,
		},
		thunkAPI,
	});

	dispatch(actionsDashboardPeople.storePerson({ deletedPeople: res, peoplePagination }));
	updateCompanyInfo(dispatch, companyId, activePeriodId);
	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'Person deleted successfully',
		}),
	);
});

const peopleSetSource = createAsyncThunk(`${NAME}/peopleSetSource`, async (payload: { peopleIds: number[]; people: SinglePerson[]; sourceInfo: SourceInfo }, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { peopleIds, people, sourceInfo } = payload;

	const peopleToUpdate: SinglePerson[] = getPeopleWithUpdatedSource(peopleIds, people, sourceInfo);

	for (const person of peopleToUpdate) {
		dispatch(
			personSave({
				people: person,
				proofContents: {},
			}),
		);
	}
});

// * Reducer
interface State {
	people: SinglePerson[];
	peoplePagination: PaginationI;
	status: RequestStatus;
}

export const initialState: State = {
	people: [],
	peoplePagination: {
		pageIndex: 0,
		pageSize: 10,
		pageCount: 0,
	},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		storePerson: (state, action: { payload: PeopleSavePayload }) => {
			const { people, peopleUpdated, peoplePagination, newPerson, deletedPeople } = action.payload;

			if (peopleUpdated) {
				const newPeople = Array.isArray(peopleUpdated) ? peopleUpdated : [peopleUpdated];

				const allPeopleUpdated = removeDuplicates([...state.people, ...newPeople], 'id');

				return {
					...state,
					people: allPeopleUpdated,
					peoplePagination,
				};
			}

			if (people) {
				state.people = Array.isArray(people) ? people : [people]; // newAllPeople
				state.peoplePagination = peoplePagination;
			}

			if (newPerson) {
				state.people = [...state.people, ...newPerson];
			}

			if (deletedPeople) {
				const newDeletedPeople = Array.isArray(deletedPeople) ? deletedPeople.map(person => person.id) : [deletedPeople.id];

				let peopleUpdated: SinglePerson[] = [...state.people];

				for (let i = 0; i < newDeletedPeople.length; i++) {
					peopleUpdated = peopleUpdated.filter(person => person.id !== newDeletedPeople[i]);
				}

				state.people = peopleUpdated;
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(personSave.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(personSave.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(personSave.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(personCreate.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(personCreate.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(personCreate.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(peopleDelete.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(peopleDelete.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(peopleDelete.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsDashboardPeople = {
	...slice.actions,
	personSave,
	personCreate,
	peopleDelete,
	peopleSetSource,
};
