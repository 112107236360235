import { FC, HTMLAttributes } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DICTItem } from 'src/app/redux/slices/metaDictionaries/types';
import { GeneratedField, StatusType } from 'src/pages/DashboardPage/_BLL/dashboard/types/types';
import { useFilledOnce } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/_hooks/useFilledOnce';
import { cancelVerify } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/_libs/cancelVerify';
import { uniqueArray } from 'src/shared/lib/object_array';
import { Select } from 'src/shared/ui/_inputs/Select';
import { InfoTooltip } from 'src/shared/ui/_tooltips/InfoTooltip';
import s from './DropDownManyField.module.scss';
import { getFieldNameCompany } from 'src/pages/DashboardPage/lib/getFieldNameCompany';

type Props = GeneratedField & HTMLAttributes<HTMLDivElement>;

export const DropDownManyField: FC<Props> = props => {
	const {
		namePrefix, //
		propertyName,
		internalName,
		disabled,
		validate,
		fieldDictionary,
		customLogic,
	} = props;

	// * Form
	const COMMAND__SELECT_All = 'COMMAND__SELECT_All';
	const COMMAND__DESELECT = 'COMMAND__DESELECT';

	const { setValue, getValues, control } = useFormContext();

	const NAME = getFieldNameCompany({ namePrefix, propertyName, internalName });

	// ! Industry and sector ONLY...
	// - Primary
	const primarySectorName = 'properties.industryAndSectorPrimary.sector';
	const primaryIndustryName = 'properties.industryAndSectorPrimary.industry';

	const sectorPrimaryValue: string | null = useWatch({
		name: `${primarySectorName}.value`,
	});

	const industryPrimaryValue: string | null = useWatch({
		name: `${primaryIndustryName}.value`,
	});

	// - Secondary
	const secondarySectorName = 'properties.industryAndSectorSecondary.sector';
	const secondaryIndustryName = 'properties.industryAndSectorSecondary.industry';

	const sectorSecondaryValues: string[] | null = useWatch({
		name: `${secondarySectorName}.values`,
	});

	const industrySecondaryValues: string[] | null = useWatch({
		name: `${secondaryIndustryName}.values`,
	});
	// ! ...Industry and sector ONLY

	const handleChange = (fieldName: string, values: Array<string | string[]>) => {
		const toUniqueFlatArray = (values: Array<string | string[]>) => {
			return uniqueArray(values.flat());
		};

		cancelVerify(namePrefix, propertyName, internalName, setValue, getValues);

		if (values.length === 0 || values.find(value => value === COMMAND__DESELECT)) {
			setValue(fieldName, null);
			setValue(`${NAME}.status`, StatusType.EMPTY);

			// ! Industry and sectors ONLY...
			// When primary sector value changes nullify primary industry.
			if (NAME === secondarySectorName) {
				setValue(`${secondaryIndustryName}.values`, null);
				setValue(`${secondaryIndustryName}.status`, StatusType.EMPTY);
			}
			// ! ...Industry and sectors ONLY.

			// ! People only...
			if (propertyName === 'nationality') {
				setValue(`${NAME}.dataProcessingType`, null); // set to Reported if not null.
			}
			// ! ...People only
		} else if (values.find(value => value === COMMAND__SELECT_All)) {
			const allValues = fieldDictionary ? dictionaryFilter(fieldDictionary).map(dictionary => String(dictionary.id)) : [];
			setValue(`${NAME}.status`, StatusType.EMPTY);
			setValue(fieldName, allValues);
		} else {
			setValue(`${NAME}.status`, StatusType.FILLED);
			setValue(fieldName, toUniqueFlatArray(values));

			// ! Industry and sectors ONLY...
			filterIndustries(values.flat());
			// ! ...Industry and sectors ONLY

			// ! People only...
			if (propertyName === 'nationality') {
				setValue(`${NAME}.dataProcessingType`, 0); // set to Reported if not null.
			}
			// ! ...People only
		}
	};

	// ! Sector only ...
	// The function removes existing sectors when the industry is removed from selected.
	// Example: If sector Agriculture is removed the industries of the agriculture also removed.
	// TODO: Remove selected in primary sector.
	const filterIndustries = (sectorValues: string[]) => {
		if (sectorValues && industrySecondaryValues && NAME === secondarySectorName) {
			let filteredIndustryValues: string[] = [];

			for (const value of sectorValues) {
				filteredIndustryValues = [
					...filteredIndustryValues,
					...industrySecondaryValues.filter(industryValue => industryValue.substring(0, 2) === value.substring(0, 2) && industryValue.length === value.length),
				];
			}

			if (industrySecondaryValues.length === 0) {
				setValue(`${secondaryIndustryName}.values`, null);
			} else {
				setValue(`${secondaryIndustryName}.values`, uniqueArray(filteredIndustryValues));
			}
		}
	};
	// ! ...

	const dictionaryFilter = (dictionary: DICTItem[]): DICTItem[] => {
		if (NAME === secondaryIndustryName) {
			if (sectorSecondaryValues) {
				let filteredDictionary: DICTItem[] = [];

				// return only the industries that related to selected sectors.
				for (const value of sectorSecondaryValues) {
					filteredDictionary = [...filteredDictionary, ...dictionary.filter(item => String(item.id).slice(0, -3) + '000' === value)];
				}

				// Removes the option of sector selected in primary industry.
				if (industryPrimaryValue !== null) {
					filteredDictionary = filteredDictionary.filter(industryValue => String(industryValue.id) !== industryPrimaryValue);
				}

				return filteredDictionary;
			} else {
				return [];
			}
		} else {
			return dictionary;
		}
	};

	// * Custom logic
	// - Filled once
	const { isDisabled } = useFilledOnce(NAME, getValues, 'values', disabled, customLogic);

	// * Conditions
	// ! Secondary industries and sectors ONLY ...
	const disableSector = NAME === secondarySectorName && sectorPrimaryValue === null;
	const disableIndustry = NAME === secondaryIndustryName && sectorSecondaryValues === null;
	// ! ...
	const fieldDisabled = isDisabled || disableIndustry || disableSector;

	// * Render
	return (
		<Controller
			name={`${NAME}.values`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div className={s.fieldWrapper}>
					<Select
						{...field}
						className={s.field}
						mode="multiple"
						maxTagCount="responsive"
						placement="bottomLeft"
						maxTagPlaceholder={omittedValues => <InfoTooltip info={omittedValues.map(({ label }) => String(label))} />}
						filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
						options={
							fieldDictionary && [
								{ value: COMMAND__SELECT_All, label: 'Select All' },
								{ value: COMMAND__DESELECT, label: 'Deselect All' },
								...dictionaryFilter(fieldDictionary).map(dictionary => ({ value: String(dictionary.id), label: dictionary.name })),
							]
						}
						onChange={value => {
							handleChange(field.name, value);
						}}
						disabled={fieldDisabled}
						error={fieldState.error?.message}
					/>
				</div>
			)}
		/>
	);
};
