import { localStorageName } from 'src/app/redux/localStorage';
import { RootState } from 'src/app/redux/rootReducer';
import { GASendApiCall } from 'src/shared/api/GoogleAnalytics';
import { rtkHandleResponse } from 'src/shared/api/rtkHandleResponse';
import { State } from 'src/pages/AuthPage/_BLL/slice';

// Prod - https://denominator-api-silver-prod.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com
// Dev - https://denominator-api-silver-dev.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com
// Special - dr3t6su5bd
const host = process.env.REACT_APP_HOST || 'Error... in building REACT_APP_HOST';
//const matchHost = 'https://dr3t6su5bd.execute-api.us-east-2.amazonaws.com/Prod';
const matchHost = 'http://ec2-18-188-180-68.us-east-2.compute.amazonaws.com:8250';
const augmentHost = 'https://r09ivt6q55.execute-api.us-east-2.amazonaws.com/Prod';

export class ResponseError extends Error {
	severity: ErrorSeverity;
	incidentId?: string;

	constructor(message: string, severity: ErrorSeverity, incidentId?: string, ...params: any) {
		super(...params);
		this.severity = severity;
		this.message = message;
		this.incidentId = incidentId;
	}
}

const handleResponse = async (response: any) => {
	const parsedResponse = response.status === 204 ? '' : await response.json();

	if (response.status < 200 || response.status >= 300) {
		if (response.status !== 404) { // There is no reason to show error on 404.
			if ('severity' in parsedResponse && 'message' in parsedResponse) {
				const {severity, message, incidentId} = parsedResponse;
				if (severity === 'Information') throw new ResponseError(message, severity);
				if (severity === 'Warning') throw new ResponseError(message, severity);
				if (severity === 'Error') throw new ResponseError(message, severity);
				if (severity === 'AccessDenied') throw new ResponseError(message, severity);
				if (severity === 'Critical') throw new ResponseError(message, severity, incidentId);
			} else {
				throw new ResponseError('Unknown error', 'Error');
			}
		}
	} else {
		return parsedResponse;
	}
};

const getAuth = (): State | null => {
	const state = localStorage.getItem(localStorageName);
	const parsed = state ? JSON.parse(state) as RootState : null;
	return parsed ? parsed.auth : null;
};

const getHeaders = (state: State | null, token?: string) => {
	const apiToken = token || (state && state.userData ? state.userData.token : '');

	return {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
		'x-api-token': apiToken,
	};
};

const get = async (url: string, params?: string, host?: string) => {
	const auth = getAuth();
	if (auth) {
		await GASendApiCall('get', url, auth?.userData?.id);
	}

	return await fetch(`${host}/${url}${params ? '?' + params : ''}`, {
		method: 'GET',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
	});
};

const post = async <T>(url: string, data?: T, host?: string, signal?: AbortSignal) => {
	const auth = getAuth();
	if (auth) {
		await GASendApiCall('post', url, auth?.userData?.id);
	}

	return await fetch(`${host}/${url}`, {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(data),
		signal
	});
};

const del = async (url: string, params: any, host?: string) => {
	const auth = getAuth();
	if (auth) {
		await GASendApiCall('delete', url, auth?.userData?.id);
	}

	return await fetch(`${host}/${url}${params ? '?' + params : ''}`, {
		method: 'DELETE',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
	});
};

export async function getRequest(url: string, params?: string) {
	const response = await get(url, params, host);
	return handleResponse(response);
}

export async function deleteRequest(url: string, params: any) {
	const response = await del(url, params, host);
	return handleResponse(response);
}

// Backend expects some POST queries to have both POST and GET style parameters
export async function postRequestLegacy(url: string, data: any, signal?: AbortSignal) {
	const response = await post(url, data, host, signal);
	return handleResponse(response);
}

export async function postRequestMatching(url: string, data: any, params?: string) {
	const fullUrl = url + (params ? '?' + params : '');
	const response = await post(fullUrl, data, matchHost);
	return handleResponse(response);
}

export async function postRequestAugment(url: string, data: any, params?: string) {
	const fullUrl = url + (params ? '?' + params : '');
	const response = await post(fullUrl, data, augmentHost);
	return handleResponse(response);
}

export async function postRequest<T>(args: {
	url: string
	params?: string
	body?: T
	signal?: AbortSignal
}) {
	const {url, params, body, signal} = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('post', url, auth?.userData?.id);
	}

	const response = await fetch(`${host}/${url}${params ? '?' + params : ''}`, {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(body),
		signal
	});

	return handleResponse(response);
}

// * RTK api
export const getRequestUrl = (url: string, params?: string) => {
	return `${host}/${url}${params ? '?' + params : ''}`;
};

export const generateParams = (params?: object) => {
	return params
		? Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')
		: '';
};

// * RTK pure requests
interface FetchGET {
	url: string,
	params?: object,
	signal?: AbortSignal,
	token?: string
}

const rtkGET = async (args: FetchGET) => {
	const {url, params, signal, token} = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('get', url, auth?.userData?.id);
	}

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'GET',
		headers: getHeaders(auth, token),
		referrerPolicy: 'no-referrer',
		signal
	});
};

interface FetchPOST {
	url: string,
	params?: object
	payload?: object,
	signal?: AbortSignal
}

const rtkPOST = async (args: FetchPOST) => {
	const {url, params, payload, signal} = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('post', url, auth?.userData?.id);
	}

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal
	});
};

interface FetchPUT {
	url: string,
	params?: object
	payload?: object,
	signal?: AbortSignal
}

const rtkPUT = async (args: FetchPUT) => {
	const {url, params, payload, signal} = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('put', url, auth?.userData?.id);
	}

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'PUT',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(payload),
		signal
	});
};

interface FetchDEL {
	url: string,
	params?: object,
	signal?: AbortSignal
}

const rtkDEL = async (args: FetchDEL) => {
	const {url, params, signal} = args;

	const auth = getAuth();
	if (auth) {
		await GASendApiCall('delete', url, auth?.userData?.id);
	}

	return await fetch(getRequestUrl(url, generateParams(params)), {
		method: 'DELETE',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		signal
	});
};

// * RTK request handlers
const rtkGETRequest = async <RES>(args: FetchGET & { thunkAPI: any }) => {
	const {thunkAPI, ...getArgs} = args;

	const response = await rtkGET(getArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI
	});
};

const rtkGETRequestBLOB = async <RES>(args: FetchGET & { thunkAPI: any }) => {
	const {thunkAPI, ...getArgs} = args;

	const response = await rtkGET(getArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI,
		type:'blob'
	});
};

const rtkPOSTRequest = async <RES>(args: FetchPOST & { thunkAPI: any, displayNotifications?: boolean }) => {
	const {thunkAPI, displayNotifications, ...postArgs} = args;

	const response = await rtkPOST(postArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI,
		displayNotifications
	});
};

const rtkPUTRequest = async <RES>(args: FetchPUT & { thunkAPI: any }) => {
	const {thunkAPI, ...postArgs} = args;

	const response = await rtkPUT(postArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI
	});
};

const rtkDELRequest = async <RES>(args: FetchDEL & { thunkAPI: any }) => {
	const {thunkAPI, ...delArgs} = args;

	const response = await rtkDEL(delArgs);

	return await rtkHandleResponse<RES>({
		response,
		thunkAPI
	});
};

// * Export
export const rtkApiRequest = {
	rtkGETRequest,
	rtkGETRequestBLOB,
	rtkPOSTRequest,
	rtkPUTRequest,
	rtkDELRequest,
};

// * Types
export type SignInBody = {
	username: string
	password: string
}

export type ErrorSeverity = 'Information'
	| 'Warning'
	| 'Error'
	| 'AccessDenied'
	| 'Critical'
