import cn from 'classnames';
import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { Outlier, OutlierStatus } from 'src/pages/QcAutomationPage/_BLL/types';
import { StatusCircle } from 'src/pages/QcAutomationPage/ui/StatusCircle';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonSecondary } from 'src/shared/ui/_buttons/ButtonSecondary';
import s from './OutlierItem.module.scss';
import { checkIfLink, cleanLink } from 'src/shared/lib/string';

interface Props extends Outlier {
	onButtonClick: (newStatus: OutlierStatus) => void;
}

export const OutlierItem: FC<Props> = props => {
	const {
		datapointID, //
		datapointName,
		values,
		outlierDatapointID,
		status,
		ruleID,
		ruleMessage,
		ruleText,
		silverProperty,
		source,
		sourceUrl,
		onButtonClick,
	} = props;

	// * Selector
	const loadingStatus = useAppSelector(state => state.qcAutomation.status);
	const companyMeta = useAppSelector(state => state.metaDictionaries.meta.company);

	let dataPointOrder = '';

	if (companyMeta) {
		for (const meta of Object.values(companyMeta)) {
			typeof meta !== 'string' &&
				meta.length > 0 &&
				meta.forEach((tab: any, tabIndex: number) => {
					tab.groups.forEach((group: any, groupIndex: number) => {
						group.properties.forEach((property: any, propertyIndex: number) => {
							if (property.displayName === silverProperty) {
								dataPointOrder = `${tabIndex}.${groupIndex + 1}.${propertyIndex + 1}`;
							}
						});
					});
				});
		}
	}

	// * Render
	return (
		<div className={s.container}>
			<div className={s.dataPointInfo}>
				<div className={s.caption}>{dataPointOrder}</div>
				<div className={s.title}>{silverProperty}</div>
				<StatusCircle status={status} />
			</div>

			{source && (
				<div className={s.dataPointInfo}>
					<div className={s.caption}>source</div>
					<div className={s.title}>{source}</div>
					{sourceUrl && (
						<a
							href={checkIfLink(sourceUrl)}
							target="_blank"
							rel="noreferrer"
						>
							{cleanLink(sourceUrl)}
						</a>
					)}
				</div>
			)}

			<div className={s.values}>
				<span className={s.ruleMessage}>{ruleMessage}</span>

				<div className={s.caption}>Current value:</div>
				{values ? values.map((value, index) => <div key={index}>{value}</div>) : <div>Empty</div>}
			</div>

			<div className={s.buttons}>
				{status !== 'Accepted' && (
					<ButtonSecondary
						className={cn(s.button, s.button_success)}
						onClick={() => onButtonClick('Accepted')}
						disabled={loadingStatus === RequestStatus.loading}
					>
						Accept outliers
					</ButtonSecondary>
				)}

				{status !== 'Error' && (
					<ButtonSecondary
						className={cn(s.button, s.button_error)}
						onClick={() => onButtonClick('Error')}
						disabled={loadingStatus === RequestStatus.loading}
					>
						Reject outliers
					</ButtonSecondary>
				)}
			</div>
		</div>
	);
};
