import cn from 'classnames';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { useAppSelector } from 'src/app/redux/createAction';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { DataPoint, SavePayload } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { PersonMergeMeta } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { dataPointsToString } from 'src/pages/MergeToolPage/lib/dataPointsToString';
import { SourceIcon } from 'src/pages/MergeToolPage/ui/SilverMode/SourceIcon';
import { TargetIcon } from 'src/pages/MergeToolPage/ui/SilverMode/TargetIcon';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import s from './ConflictsTableSelectable.module.scss';

interface Props {
	personMergeMeta: PersonMergeMeta;
	dictionaries: Dictionaries;
}

export const ConflictsTableSelectable: FC<Props> = props => {
	const {
		personMergeMeta, //
		dictionaries,
	} = props;

	const { personIds, conflicts } = personMergeMeta;

	// * Actions
	const dispatch = useDispatch();
	const { mergeSinglePerson } = actionsMergeToolDataPoints;

	// * Selectors
	const lastDiffPayload = useAppSelector(state => state.mergeToolDataPoints.lastDiffPayload);

	const sourceId = conflicts[0].values[0].source.companyId;
	const targetId = conflicts[0].values[1].source.companyId;

	const targetPersonId = personIds[1] as number;

	const autoMergedValues = conflicts
		.filter(conflict => conflict.propertyName !== 'personId' && conflict.propertyName !== 'companyId')
		.map(conflict => ({
			...conflict.currentValue[0],
			value: conflict.autoMergedValue ? conflict.autoMergedValue.datapoints[0].value : conflict.currentValue[0].value,
		}));

	const [selectedValues, setSelectedValues] = useState(autoMergedValues);

	const isActive = (dataPoints: DataPoint[]) => {
		const dataPointId = dataPoints[0].datapointId;
		const currentValue = selectedValues.find(value => value.datapointId === dataPointId);
		return currentValue?.value === dataPoints[0].value;
	};

	const onValueClick = (dataPoints: DataPoint[]) => {
		const dataPoint = dataPoints[0];
		// TODO: Replace with map
		const restValues = selectedValues.filter(value => value.datapointId !== dataPoint.datapointId);
		setSelectedValues([...restValues, { ...dataPoint }]);
	};

	const onMerge = () => {
		const payload: (Omit<SavePayload, 'target'> & { personId: number }) | null = lastDiffPayload && {
			personId: targetPersonId,
			values: selectedValues,
		};

		payload &&
			dispatch(
				mergeSinglePerson({
					reqPayload: payload,
					personName: personMergeMeta.name,
				}),
			);
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.name}>{personMergeMeta.name}</div>

			<table>
				<tr>
					<th colSpan={3}>Conflicts</th>
				</tr>

				<tr>
					<td></td>
					<td className={s.subheaderCell}>
						<b>
							<SourceIcon />
							{sourceId}
						</b>
					</td>

					<td className={s.subheaderCell}>
						<b>
							<TargetIcon />
							{targetId}
						</b>
					</td>
				</tr>

				{conflicts.map(conflict => {
					const { propertyName, displayName } = conflict;
					const dataPointsSource = conflict.values[0].datapoints;
					const dataPointsTarget = conflict.values[1].datapoints;
					const isEditableSource = dataPointsSource[0].isEditable;
					const isEditableTarget = dataPointsTarget[0].isEditable;

					return (
						<React.Fragment key={propertyName}>
							<tr>
								<td>
									<div className={s.displayName}>{displayName}</div>
								</td>

								{/* TODO: Create a separate component 1/2 */}
								{propertyName !== 'personId' && propertyName !== 'companyId' ? (
									<td
										className={cn(s.inputCell, !isEditableSource && s.inputCell_readOnly, isActive(dataPointsSource) && s.inputCell_active)}
										onClick={() => isEditableSource && onValueClick(dataPointsSource)}
									>
										{dataPointsToString(dataPointsSource, dictionaries)}
									</td>
								) : (
									<td>{dataPointsToString(dataPointsSource, dictionaries)}</td>
								)}

								{/* TODO: Create a separate component 2/2 */}
								{propertyName !== 'personId' && propertyName !== 'companyId' ? (
									<td
										className={cn(s.inputCell, !isEditableTarget && s.inputCell_readOnly, isActive(dataPointsTarget) && s.inputCell_active)}
										onClick={() => isEditableTarget && onValueClick(dataPointsTarget)}
									>
										{dataPointsToString(dataPointsTarget, dictionaries)}
									</td>
								) : (
									<td>{dataPointsToString(dataPointsTarget, dictionaries)}</td>
								)}
							</tr>
						</React.Fragment>
					);
				})}
			</table>

			{lastDiffPayload && <ButtonPrimary onClick={onMerge}>Merge to target</ButtonPrimary>}
		</div>
	);
};
