import cn from 'classnames';
import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useAppSelector } from 'src/app/redux/createAction';
import { GeneratedField, StatusType } from 'src/pages/DashboardPage/_BLL/dashboard/types/types';
import { useFilledOnce } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/_hooks/useFilledOnce';
import { cancelVerify } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/_libs/cancelVerify';
import { useDisabledReportLink } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/StringField/lib/useDisabledReportLink';
import { StringInput } from 'src/shared/ui/_inputs/StringInput';
import { InfoTooltip } from 'src/shared/ui/_tooltips/InfoTooltip';
import s from './StringField.module.scss';
import { getFieldNameCompany as getFieldName } from 'src/pages/DashboardPage/lib/getFieldNameCompany';
import { nullToString } from 'src/shared/lib/string';

export const StringField: FC<GeneratedField> = props => {
	const {
		namePrefix, //
		propertyName,
		internalName,
		disabled,
		measureUnits,
		validate,
		customLogic,
	} = props;

	// * Form
	const { setValue, getValues, control } = useFormContext();

	const NAME = getFieldName({ namePrefix, propertyName, internalName });

	const textFieldOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
		cancelVerify(namePrefix, propertyName, internalName, setValue, getValues);

		const value = event.currentTarget.value.trim();

		if (value === '') {
			setValue(`${NAME}.status`, StatusType.EMPTY);
			setValue(fieldName, null);
		} else {
			setValue(`${NAME}.status`, StatusType.FILLED);
			setValue(fieldName, event.target.value);
		}
	};

	// * Custom logic
	// - Filled once
	const { isDisabled } = useFilledOnce(NAME, getValues, 'value', disabled, customLogic);

	// - Disabled by role
	let disabledByRole = false;

	const activeMenu = useAppSelector(state => state.dashboardMain.activeMenu);
	const personRoleType = useWatch({ name: `${namePrefix}roleType.dataPoint.value`, control });

	if (propertyName === 'execTitle' || propertyName === 'boardTitle') {
		if (propertyName === 'execTitle' && activeMenu === 'Board of Directors' && personRoleType !== '3') {
			disabledByRole = true;
		}
		if (propertyName === 'boardTitle' && activeMenu === 'Executives' && personRoleType !== '3') {
			disabledByRole = true;
		}
	}

	const reportLinkDisabled = useDisabledReportLink(propertyName, namePrefix, internalName);

	const fieldDisabled = disabledByRole || isDisabled || reportLinkDisabled;

	// * Render
	return (
		<Controller
			name={`${NAME}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<div>
					<div className={s.fieldWrapper}>
						<StringInput
							className={cn(s.textField, propertyName === 'name' && s.textField_isName)}
							value={nullToString(field.value)}
							onChange={event => textFieldOnChangeHandler(event, field.name)}
							onBlur={event => field.value !== null && setValue(field.name, event.currentTarget.value.trim())}
							disabled={fieldDisabled}
							addonAfter={<InfoTooltip info={nullToString(field.value)} />}
							suffix={measureUnits}
							error={fieldState.error?.message}
						/>
					</div>
				</div>
			)}
		/>
	);
};
