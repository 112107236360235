import { useEffect, useState } from 'react';
import { CustomLogic } from '../../../../_BLL/dashboard/types/types';
import { FieldValues, UseFormGetValues } from 'react-hook-form';

// Check if the field must be filled only once, disable the field if it's already filled.
export const useFilledOnce = (name: string, getValues: UseFormGetValues<FieldValues>, valueType: 'value' | 'values', disabled: boolean, customLogic?: CustomLogic | null) => {
	const [initialValue, setInitialValue] = useState(null);

	useEffect(() => {
		customLogic === 'filledOnce' && setInitialValue(getValues(name)[valueType]);
	}, [disabled]);

	const isDisabled = disabled || (customLogic === 'filledOnce' && initialValue !== null);

	return {
		isDisabled,
	};
};
