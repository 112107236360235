import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import { RouterPath } from 'src/app/router/AppRouter';
import { CompanyBaseInfo } from 'src/pages/MainPage/_BLL/main_search/types';
import CircleA from 'src/shared/assets/svg/circleA.svg';
import ChildCompanySVG from 'src/shared/assets/svg/companyInheritance/child_company.svg';
import ParentCompanySVG from 'src/shared/assets/svg/companyInheritance/parent_company.svg';
import Star from 'src/shared/assets/svg/star.svg';
import { Tooltip } from 'src/shared/ui/_tooltips/Tooltip';
import s from './SearchBarResult.module.scss';

export const SearchBarResult: FC<ListChildComponentProps<CompanyBaseInfo[]>> = props => {
	const {
		index, // Indexes of react-window
		style, // Default styles of react-window
		data,
	} = props;

	// * Router
	const navigate = useNavigate();

	function handleClick(e: any, id: number) {
		if (e.shiftKey || e.ctrlKey) {
			window.open(RouterPath.dashboard + `/${id}`);
		} else {
			navigate(RouterPath.dashboard + `/${id}`);
		}
	}

	// * Render
	return (
		<div
			className={s.container}
			onClick={e => handleClick(e, data[index].id)}
			style={style}
			key={index}
		>
			<span className={s.inner}>
				{data[index].latinName ? (
					<Tooltip title={`Original name: ${data[index].name}`}>
						<b>{data[index].latinName}</b>
					</Tooltip>
				) : (
					<b>{data[index].name}</b>
				)}
				{data[index].id && <span>, {data[index].id}</span>}
				{data[index].country && <span>, {data[index].country}</span>}
				{data[index].city && <span>, {data[index].city}</span>}
				{data[index].priority && <Star />}
				{data[index].hasAdvancedRating && <CircleA />}
				{data[index].associatedParentName && <ChildCompanySVG />}
				{data[index].isParent && <ParentCompanySVG />}
				{data[index].status === 'Inactive' && <span className={s.inactiveTag}>Inactive</span>}
			</span>
		</div>
	);
};
