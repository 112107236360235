import { PersonDIFF } from "src/pages/MergeToolPage/_BLL/data_points/types";
import { PersonMergeMeta } from "src/pages/MergeToolPage/_BLL/silver_people/types";

export const getPersonMeta = (personDIFF: PersonDIFF, index: string): PersonMergeMeta => {
	const formID = index;

	const sourcePerson = personDIFF.presence[0];
	const targetPerson = personDIFF.presence[1];

	const presentInSource = sourcePerson.isPersent;
	const presentInTarget = targetPerson.isPersent;
	const presentInBoth = presentInSource && presentInTarget;
	const hasConflicts = presentInBoth && personDIFF.conflicts.filter(conflict => conflict.propertyName !== 'personId' && conflict.propertyName !== 'companyId').length > 0; // personId && companyId doesn't count as conflicts.

	const isAccepted = personDIFF.acceptedBy && personDIFF.acceptedAtUTC;
	const acceptOptionName = isAccepted ? 'Remove accepted mark' : 'Mark as accepted';
	const acceptMode = isAccepted ? 'UnAccept' : 'Accept';

	const SourceTargetPayload = {
		targetPersonId: targetPerson.personID,
		source: sourcePerson.source,
		sourcePersonId: sourcePerson.personID,
	};

	if (presentInSource && !presentInTarget) {
		// New
		// Add to target with source data [Override] | add to target and edit [NameOnly -> edit screen] | ignore [No mode]
		return {
			formID,
			name: personDIFF.name,
			personIds: [sourcePerson.personID, targetPerson.personID],
			conflicts: personDIFF.conflicts,
			conflictType: 'new',
			conflictTypeDescriptions: 'List of people that exist only in the source company.',
			options: [
				{
					optionName: 'Add person, with source data points',
					payload: {
						...SourceTargetPayload,
						mode: 'Override',
					},
					edit: false,
				},
				{
					optionName: 'Move the person from source to target',
					payload: {
						...SourceTargetPayload,
						mode: 'Move',
					},
					edit: false,
				},
				{
					optionName: 'Do not merge this person',
					payload: null,
					edit: false,
				},
			],
			acceptedAtUTC: personDIFF.acceptedAtUTC,
			acceptedBy: personDIFF.acceptedBy,
		};
	} else if (presentInTarget && !presentInSource) {
		// Unique to target
		// Add to source [OverrideSource] | Ignore [Keep]
		return {
			formID,
			name: personDIFF.name,
			personIds: [sourcePerson.personID, targetPerson.personID],
			conflicts: personDIFF.conflicts,
			conflictType: 'unique',
			conflictTypeDescriptions: 'List of people that exist only in the target company.',
			options: [
				{
					optionName: 'Do nothing. The person exists only in target',
					payload: {
						...SourceTargetPayload,
						mode: 'Keep',
					},
					edit: false,
				},
				{
					optionName: 'Add the person to source company',
					payload: {
						...SourceTargetPayload,
						mode: 'OverrideSource',
					},
					edit: false,
				},
				// {
				// 	optionName: acceptOptionName,
				// 	payload: {
				// 		...SourceTargetPayload,
				// 		mode: acceptMode,
				// 	},
				// 	edit: false
				// },
			],
			acceptedAtUTC: personDIFF.acceptedAtUTC,
			acceptedBy: personDIFF.acceptedBy,
		};
	} else if (presentInBoth && hasConflicts) {
		// Exists with conflicts
		// keep target data [Keep] | Accept source [Override] | edit [Keep -> edit screen]
		return {
			formID,
			name: personDIFF.name,
			personIds: [sourcePerson.personID, targetPerson.personID],
			conflicts: personDIFF.conflicts,
			conflictType: 'conflicts',
			conflictTypeDescriptions: 'List of people that exist in both companies but have conflicting data points.',
			options: [
				{
					optionName: 'Keep all target data points',
					payload: {
						...SourceTargetPayload,
						mode: 'Keep',
					},
					edit: false,
				},
				{
					optionName: 'Accept all source data points',
					payload: {
						...SourceTargetPayload,
						mode: 'Override',
					},
					edit: false,
				},
				{
					optionName: 'Auto merge - Accept selected',
					payload: {
						...SourceTargetPayload,
						mode: 'AutoMerge',
					},
					edit: false,
				},
				{
					optionName: acceptOptionName,
					payload: {
						...SourceTargetPayload,
						mode: acceptMode,
					},
					edit: false,
				},
			],
			acceptedAtUTC: personDIFF.acceptedAtUTC,
			acceptedBy: personDIFF.acceptedBy,
		};
	} else {
		// Exists with no conflicts
		// ignore [Keep]
		return {
			formID,
			name: personDIFF.name,
			personIds: [sourcePerson.personID, targetPerson.personID],
			conflicts: personDIFF.conflicts,
			conflictType: 'same',
			conflictTypeDescriptions: 'List of people that exist in both companies and have no conflicts.',
			options: [
				{
					optionName: 'Keep the person in target',
					payload: {
						...SourceTargetPayload,
						mode: 'Keep',
					},
					edit: false,
				},
				{
					optionName: acceptOptionName,
					payload: {
						...SourceTargetPayload,
						mode: acceptMode,
					},
					edit: false,
				},
			],
			acceptedAtUTC: personDIFF.acceptedAtUTC,
			acceptedBy: personDIFF.acceptedBy,
		};
	}
};