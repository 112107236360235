import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/app/redux/createAction';
import { CompanyBaseInfo } from 'src/pages/MainPage/_BLL/main_search/types';
import { actionsMergeToolDataPoints } from 'src/pages/MergeToolPage/_BLL/data_points/slice';
import { PersonDIFF } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { PersonMergeOptions, personPayload } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { PeopleResult } from 'src/pages/MergeToolPage/ui/SilverMode/PeopleMergeForm/PeopleResult/PeopleResult';
import { disabledButtonOption } from 'src/pages/MergeToolPage/ui/SilverMode/PeopleMergeForm/PersonField/PersonField/PersonField';
import { TargetIcon } from 'src/pages/MergeToolPage/ui/SilverMode/TargetIcon';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { CancelLongQueryButton } from '../../../CancelLongQueryButton';
import s from './PeopleMergeForm.module.scss';
import { getPersonMeta } from './lib/getPersonMeta';

interface Props {
	targetCompany: CompanyBaseInfo;
	persons: PersonDIFF[];
}

export const PeopleMergeForm: FC<Props> = props => {
	const {
		targetCompany, //
		persons,
	} = props;

	// * Actions
	const dispatch = useDispatch();
	const { mergePeople } = actionsMergeToolDataPoints;

	// * Selectors
	const queryId = useAppSelector(state => state.mergeToolDataPoints.queryId);
	const status = useAppSelector(state => state.mergeToolDataPoints.status);

	const peopleMeta = persons.map((person, index) => getPersonMeta(person, index.toString()));

	// * Form
	const createDefaultValues = () => {
		const values: PersonMergeOptions = {};
		peopleMeta.forEach(person => {
			values[person.formID] = {
				name: person.name,
				selectedOption: { ...person.options[0] },
			};
		});

		return values;
	};

	const defaultValues = createDefaultValues();

	const formMethods = useForm({
		defaultValues,
	});

	const { watch } = formMethods;

	const values = watch();
	const selectedOptionsNames = Object.values(values).map(value => value.selectedOption.optionName);

	const onSubmit = (values: PersonMergeOptions) => {
		const personsToMerge = Object.values(values)
			.filter(value => value.selectedOption.payload !== null)
			.map(value => value.selectedOption.payload) as personPayload[];

		dispatch(
			mergePeople({
				personsToMerge,
			}),
		);
	};

	const submitForm = formMethods.handleSubmit(onSubmit);

	// * Render
	return (
		<form>
			<FormProvider {...formMethods}>
				<div className={s.card}>
					<div className={s.header}>
						<div className={s.targetInfo}>
							<TargetIcon />

							<div>
								<div className={s.companyInfoRow}>
									<div className={s.caption}>DEI ID:</div>
									<div className={s.companyInfoRow__value}>{targetCompany.id}</div>
								</div>

								<div className={s.companyInfoRow}>
									<div className={s.caption}>Name:</div>
									<div className={s.companyInfoRow__value}>{targetCompany.name}</div>
								</div>
							</div>
						</div>

						{queryId ? (
							<CancelLongQueryButton queryId={queryId} />
						) : (
							<ButtonPrimary
								onClick={submitForm}
								disabled={!selectedOptionsNames.find(optionName => !disabledButtonOption.includes(optionName)) || status === RequestStatus.loading}
							>
								Apply all actions
							</ButtonPrimary>
						)}
					</div>

					<PeopleResult peopleMeta={peopleMeta} />
				</div>
			</FormProvider>
		</form>
	);
};
