import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { Property } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { PersonConflictType } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { dataPointsToString } from 'src/pages/MergeToolPage/lib/dataPointsToString';
import { ConflictsTable } from 'src/pages/MergeToolPage/ui/SilverMode/PeopleMergeForm/PersonField/ConflictsTable/ConflictsTable';
import s from './PersonDataPoints.module.scss';

interface Props {
	conflictType: PersonConflictType;
	conflicts: Property[];
}

export const PersonDataPoints: FC<Props> = props => {
	const {
		conflictType, //
		conflicts,
	} = props;

	// * Selectors
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);

	// * Conditions
	const compare = conflictType === 'conflicts';

	// * Condition renders
	const renderDataPoints = (conflicts: Property[], conflictType: Exclude<PersonConflictType, 'conflicts'>, dictionaries: Dictionaries) => {
		const valuesIndex = conflictType === 'new' ? 0 : 1; // unique should show target data points.
		const companyId = conflictType === 'same' ? null : conflicts[0].values[valuesIndex].source.companyId; // save does not have conflicts;

		return (
			<>
				{companyId && (
					<>
						<div className={s.caption}>
							Data points from <b>{companyId}</b>
						</div>
						<div className={s.dataPoint}>
							{conflicts.map(conflict => (
								<div
									className={s.dataPoint__wrapper}
									key={conflict.propertyName}
								>
									<div
										className={s.caption}
										key={conflict.propertyName}
									>
										{conflict.displayName}
									</div>
									<span>{dataPointsToString(conflict.values[valuesIndex].datapoints, dictionaries)}</span>
								</div>
							))}
						</div>
					</>
				)}
			</>
		);
	};

	// * Render
	return (
		<>
			{!compare && dictionaries && renderDataPoints(conflicts, conflictType, dictionaries)}
			{compare && dictionaries && (
				<ConflictsTable
					conflicts={conflicts}
					dictionaries={dictionaries}
				/>
			)}
		</>
	);
};
