import { Select, SelectProps } from 'antd';
import { FC, useId } from 'react';
import { ErrorMessage } from '../_common/ErrorMessage';
import { Label } from '../_common/Label/Label';

export interface Option {
	id: number | string | null | undefined; // id is an actual value of the select.
	name: string; // name for display.
}

interface Props extends SelectProps {
	label?: string;
	error?: string;
	options: Option[];
}

const { Option } = Select;

export const SelectDictionary: FC<Props> = props => {
	const {
		options, //
		label, //
		error,
		status,
		className,
		...selectProps
	} = props;

	const id = useId();

	// * Render
	return (
		<div className={className}>
			{label && (
				<Label
					id={id}
					label={label}
				/>
			)}
			<div>
				<Select
					{...selectProps}
					id={id}
					status={error ? 'error' : status}
					options={options.map(option => ({ label: option.name, value: option.id }))}
					popupMatchSelectWidth={false}
				/>
			</div>

			{error && <ErrorMessage errorMessage={error} />}
		</div>
	);
};
