import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/app/redux/createAction';
import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { actionsDashboardCompany } from 'src/pages/DashboardPage/_BLL/company/slice';
import { Company } from 'src/pages/DashboardPage/_BLL/company/types/types';
import { CompanyAddUpdateREQBody } from 'src/pages/DashboardPage/_BLL/company/types/types_REQ';
import { useDashboardStatus } from 'src/pages/DashboardPage/_hooks/useDashboardStatus';
import { DropDownField } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/DropDownField/DropDownField';
import { DropDownManyField } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/DropDownManyField/DropDownManyField';
import { StringField } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/StringField/StringField';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { object, string } from 'yup';
import s from './AddNewCompany.module.scss';
import { NAME_PREFIX } from './consts';
import { StatusType } from 'src/pages/DashboardPage/_BLL/dashboard/types/types';
import { Label } from 'src/shared/ui/_inputs/_common/Label/Label';

interface Props {
	companyTemplate: Company;
	dictionaries: Dictionaries;
}

export const AddNewCompany: FC<Props> = props => {
	const {
		companyTemplate, //
		dictionaries,
	} = props;

	const status = useDashboardStatus();

	// * Router
	const navigate = useNavigate();

	// * Actions
	const dispatch = useAppDispatch();
	const { companyCreate } = actionsDashboardCompany;

	// * Form
	// - validation
	const validationSchema = object({
		properties: object({
			legalName: object({
				dataPoint: object({
					value: string().required('Company name is required').nullable(),
				}),
			}),
			legalCountry: object({
				dataPoint: object({
					value: string().required('Legal country name is required').nullable(),
				}),
			}),
			industryAndSectorPrimary: object({
				sector: object({
					value: string().required('Primary sector is required').nullable(),
				}),
				industry: object({
					value: string().required('Primary industry is required').nullable(),
				}),
			}),
		}),
	});

	// - hooks
	const formMethods = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			...companyTemplate,
			properties: {
				legalName: companyTemplate.properties.legalName,
				legalCountry: companyTemplate.properties.legalCountry,
				industryAndSectorPrimary: companyTemplate.properties.industryAndSectorPrimary,
				industryAndSectorSecondary: companyTemplate.properties.industryAndSectorSecondary,
				publicOrPrivate: companyTemplate.properties.publicOrPrivate,
				// sourceTag: companyTemplate.properties.sourceTag,
			},
		},
	});

	const { handleSubmit } = formMethods;

	// - functions
	const onSubmit = handleSubmit(formValues => {
		const requestBody: CompanyAddUpdateREQBody = {
			payload: {
				...companyTemplate,
				id: null,
				// entityName: 'company',
				// displayName: null,
				reportingPeriodId: dictionaries.reportingPriods[0].id,
				properties: {
					...formValues.properties,
					locationCountry: { ...formValues.properties.legalCountry },
					publicOrPrivate: {
						dataPoint: {
							confidence: null,
							locationInProof: null,
							proofContent: null,
							pageNumber: null,
							proofUrl: null,
							source: null,
							sourceComment: null,
							sourceDocumentId: null,
							sourceDocumentName: null,
							sourceDocumentUrl: null,
							sourceUrl: null,
							status: StatusType.FILLED,
							type: 1,
							value: 'false',
							verified: false,
							verifiedAt: null,
							verifiedBy: null,
							dataProcessingType: null,
							referenceDate: null,
							valueLevel: null,
							sourceTag: null,
							inputKind: 1,
						},
					},
				},
			},
		};

		dispatch(companyCreate(requestBody))
			.unwrap()
			.then(res => navigate(`/dashboard/${res.id}`))
			.catch(() => console.log('error'));
	});

	const industriesAndSectorOptions = dictionaries.sectors
		.map(industry => {
			if (industry.children) {
				return [
					{
						id: industry.id,
						name: industry.name,
					},
					...industry.children,
				];
			} else {
				return industry;
			}
		})
		.flat();

	const internalName = 'dataPoint';

	// * Render
	return (
		<FormProvider {...formMethods}>
			<>
				{industriesAndSectorOptions && (
					<div className={s.container}>
						<div className={s.title}>Create new company</div>

						<form
							className={s.form}
							onSubmit={onSubmit}
						>
							<div>
								<Label
									id={''}
									label="Company name"
								/>
								<div className={s.fieldWrapper}>
									<StringField
										namePrefix={NAME_PREFIX}
										propertyName="legalName"
										internalName={internalName}
										disabled={false}
									/>
								</div>
							</div>

							<div>
								<Label
									id={''}
									label="Primary Sector and Industry"
								/>

								<div className={s.fieldWrapper}>
									<DropDownField
										namePrefix={NAME_PREFIX}
										propertyName="industryAndSectorPrimary"
										internalName="sector"
										fieldDictionary={dictionaries.sectors}
										disabled={false}
									/>

									<DropDownField
										namePrefix={NAME_PREFIX}
										propertyName="industryAndSectorPrimary"
										internalName="industry"
										fieldDictionary={dictionaries.industries}
										disabled={false}
									/>
								</div>
							</div>

							<div>
								<Label
									id={''}
									label="Secondary Sector and Industry"
								/>
								<div className={s.fieldWrapper}>
									<DropDownManyField
										namePrefix={NAME_PREFIX}
										propertyName="industryAndSectorSecondary"
										internalName="sector"
										fieldDictionary={dictionaries.sectors}
										disabled={false}
									/>

									<DropDownManyField
										namePrefix={NAME_PREFIX}
										propertyName="industryAndSectorSecondary"
										internalName="industry"
										fieldDictionary={dictionaries.industries}
										disabled={false}
									/>
								</div>
							</div>

							<div>
								<Label
									id={''}
									label="Legal country"
								/>

								<div className={s.fieldWrapper}>
									<DropDownField
										namePrefix={NAME_PREFIX}
										propertyName="legalCountry"
										internalName={internalName}
										fieldDictionary={dictionaries?.countries}
										disabled={false}
									/>
								</div>
							</div>

							{/* {isDevEnv && (
				<div>
				<div className={s.caption}>Source tag</div>
				
				<div className={s.fieldWrapper}>
				<DropDownField
				namePrefix={NAME_PREFIX}
				propertyName="sourceTag"
				internalName={internalName}
				fieldDictionary={dictionaries?.sourceTags}
				disabled={false}
				/>
				</div>
				</div>
				)} */}

							<ButtonPrimary
								htmlType="submit"
								disabled={status === RequestStatus.loading}
							>
								Add new company
							</ButtonPrimary>
						</form>
					</div>
				)}
			</>
		</FormProvider>
	);
};
