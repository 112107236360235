import { RequestStatus, UrlKPI } from 'src/shared/api/types';
import {
	KpiCompaniesDataPoints,
	KpiCompaniesRating,
	KpiDatabase,
	KpiGraph,
	KpiLastEdited,
	KpiProductivity,
	kpiResponse,
	KpiTimeSpent,
	KpiTotalBy,
} from 'src/pages/MainPage/_BLL/kpi/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rtkApiRequest } from 'src/shared/api/api';
import { RootState } from 'src/app/redux/rootReducer';
import { getYesterday } from 'src/shared/lib/date';

const NAME = 'KPI';

const loadKpiPart = createAsyncThunk(`${NAME}/loadKpiPart`, async (args: { url: UrlKPI; params?: { duration: any } }, thunkAPI) => {
	const { url, params } = args;
	const { signal } = thunkAPI;

	const res = await rtkApiRequest.rtkGETRequest<kpiResponse>({
		url,
		params,
		thunkAPI,
		signal,
	});

	const resModified = (res: kpiResponse) => {
		if (url === 'kpiLastEdited') {
			const response = res as KpiLastEdited;

			return {
				...(response as KpiLastEdited),
				companies: response.companies.map(company => ({
					...company,
					silverLink: `https://silver.denominator.com/dashboard/${company.deiId}`,
					goldLink: `https://gold.denominator.com/database/${company.deiId}/1`,
				})),
			};
		} else {
			return res;
		}
	};

	return {
		res: resModified(res),
		url,
	};
});

const initializeKpi = createAsyncThunk(`${NAME}/initializeKpi`, async (args: void, thunkAPI) => {
	const { getState, dispatch } = thunkAPI;
	const state = getState() as RootState;
	const currentState = state.kpi;

	for (const url of Object.values(UrlKPI)) {
		if (url === UrlKPI.kpiProductivity24) {
			const dates = currentState[UrlKPI.kpiProductivity24].dates;
			dispatch(
				loadKpiPart({
					url,
					params: {
						duration: `${dates[0]}${encodeURI('|')}${dates[1]}`,
					},
				}),
			);
		} else if (url === UrlKPI.kpiTimeSpent) {
			const dates = currentState[UrlKPI.kpiTimeSpent].dates;
			dispatch(
				loadKpiPart({
					url,
					params: {
						duration: `${dates[0]}${encodeURI('|')}${dates[1]}`,
					},
				}),
			);
		} else {
			dispatch(
				loadKpiPart({
					url,
				}),
			);
		}
	}
});

const updatePart = createAsyncThunk(`${NAME}/updatePart`, async (args: { url: UrlKPI; startDate: Date; endDate: Date; dates?: [string | null, string | null] }, thunkAPI) => {
	const { url, startDate, endDate, dates } = args;
	const { dispatch } = thunkAPI;

	await dispatch(
		loadKpiPart({
			url,
			params: {
				duration: `${startDate.toLocaleDateString('en-US')}${encodeURI('|')}${endDate.toLocaleDateString('en-US')}`,
			},
		}),
	);

	if (dates) {
		dispatch(
			actionsKPI.saveDates({
				url,
				dates,
			}),
		);
	}
});

type ObjectWithStatus = {
	status: RequestStatus;
};

interface State {
	[UrlKPI.kpiDatabase]: ObjectWithStatus & {
		data: KpiDatabase | null;
	};
	[UrlKPI.kpiCompaniesDatapoints]: ObjectWithStatus & {
		data: KpiCompaniesDataPoints | null;
	};
	[UrlKPI.kpiTotalByRegion]: ObjectWithStatus & {
		data: KpiTotalBy | null;
	};
	[UrlKPI.kpiTotalByIndustry]: ObjectWithStatus & {
		data: KpiTotalBy | null;
	};
	[UrlKPI.kpiProductivity24]: ObjectWithStatus & {
		data: KpiProductivity | null;
		dates: [string | null, string | null];
	};
	[UrlKPI.kpiProductivityHistorical]: ObjectWithStatus & {
		data: KpiProductivity | null;
	};
	[UrlKPI.kpiTimeSpent]: ObjectWithStatus & {
		data: KpiTimeSpent | null;
		dates: [string | null, string | null];
	};
	// In development...
	[UrlKPI.kpiCompaniesRating]: ObjectWithStatus & {
		data: KpiCompaniesRating | null;
	};
	[UrlKPI.kpiPushedToGold]: ObjectWithStatus & {
		data: KpiGraph | null;
	};
	[UrlKPI.kpiAddedDatapoints]: ObjectWithStatus & {
		data: KpiGraph | null;
	};
	[UrlKPI.kpiNewCompanies]: ObjectWithStatus & {
		data: KpiGraph | null;
	};
	[UrlKPI.kpiLastEdited]: ObjectWithStatus & {
		data: KpiLastEdited | null;
	};
}

const singleCardInitialState = {
	data: null,
	status: RequestStatus.still,
};

const singleCardWithDatesInitialState = {
	...singleCardInitialState,
	dates: [new Date().toLocaleDateString('en-US'), getYesterday().toLocaleDateString('en-US')] as [string | null, string | null],
};

export const initialState: State = {
	[UrlKPI.kpiDatabase]: singleCardInitialState,
	[UrlKPI.kpiCompaniesDatapoints]: singleCardInitialState,
	[UrlKPI.kpiTotalByRegion]: singleCardInitialState,
	[UrlKPI.kpiTotalByIndustry]: singleCardInitialState,
	[UrlKPI.kpiProductivity24]: singleCardWithDatesInitialState,
	[UrlKPI.kpiProductivityHistorical]: singleCardInitialState,
	[UrlKPI.kpiTimeSpent]: singleCardWithDatesInitialState,
	// In development...
	[UrlKPI.kpiCompaniesRating]: singleCardInitialState,
	[UrlKPI.kpiPushedToGold]: singleCardInitialState,
	[UrlKPI.kpiAddedDatapoints]: singleCardInitialState,
	[UrlKPI.kpiNewCompanies]: singleCardInitialState,
	[UrlKPI.kpiLastEdited]: singleCardInitialState,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		saveDates: (state, action: PayloadAction<{ url: UrlKPI; dates: [string | null, string | null] }>) => {
			const { url, dates } = action.payload;

			const urlState = state[url];

			if ('dates' in urlState) {
				urlState.dates = dates;
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(loadKpiPart.pending, (state, action) => {
			const { url } = action.meta.arg;

			state[url].status = RequestStatus.loading;
		});
		builder.addCase(loadKpiPart.fulfilled, (state, action) => {
			const { url, res } = action.payload;

			state[url].data = res;
			state[url].status = RequestStatus.still;
		});
		builder.addCase(loadKpiPart.rejected, (state, action) => {
			const { url } = action.meta.arg;

			state[url].status = RequestStatus.failed;
		});
	},
});

export const actionsKPI = {
	...slice.actions,
	initializeKpi,
	updatePart,
};
