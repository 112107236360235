import cn from 'classnames';
import React, { FC } from 'react';
import { Dictionaries } from 'src/app/redux/slices/metaDictionaries/types';
import { DataPoint, Property } from 'src/pages/MergeToolPage/_BLL/data_points/types';
import { dataPointsToString } from 'src/pages/MergeToolPage/lib/dataPointsToString';
import { SourceIcon } from 'src/pages/MergeToolPage/ui/SilverMode/SourceIcon';
import { TargetIcon } from 'src/pages/MergeToolPage/ui/SilverMode/TargetIcon';
import s from './ConflictsTable.module.scss';

interface Props {
	conflicts: Property[];
	dictionaries: Dictionaries;
}

export const ConflictsTable: FC<Props> = props => {
	const {
		conflicts, //
		dictionaries,
	} = props;

	const sourceId = conflicts[0].values[0].source.companyId;
	const targetId = conflicts[0].values[1].source.companyId;

	const autoMergedValues = conflicts
		.filter(conflict => conflict.propertyName !== 'personId' && conflict.propertyName !== 'companyId')
		.map(conflict => ({
			datapointId: conflict.currentValue[0].datapointId,
			value: conflict.autoMergedValue ? conflict.autoMergedValue.datapoints[0].value : conflict.currentValue[0].value,
		}));

	const isActive = (dataPoints: DataPoint[]) => {
		const dataPointId = dataPoints[0].datapointId;
		const currentValue = autoMergedValues.find(value => value.datapointId === dataPointId);
		return currentValue?.value === dataPoints[0].value;
	};

	// * Render
	return (
		<table className={s.table}>
			<tr>
				<th colSpan={3}>Conflicts</th>
			</tr>

			<tr>
				<td></td>
				<td className={s.subheaderCell}>
					<b>
						<SourceIcon />
						{sourceId}
					</b>
				</td>

				<td className={s.subheaderCell}>
					<b>
						<TargetIcon />
						{targetId}
					</b>
				</td>
			</tr>

			{conflicts.map(conflict => (
				<React.Fragment key={conflict.propertyName}>
					<tr>
						<td>
							<div className={s.displayName}>{conflict.displayName}</div>
						</td>

						<td className={cn(s.dataCell, isActive(conflict.values[0].datapoints) && s.dataCell_active)}>{dataPointsToString(conflict.values[0].datapoints, dictionaries)}</td>

						<td className={cn(s.dataCell, isActive(conflict.values[1].datapoints) && s.dataCell_active)}>{dataPointsToString(conflict.values[1].datapoints, dictionaries)}</td>
					</tr>
				</React.Fragment>
			))}
		</table>
	);
};
