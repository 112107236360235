import { FC } from 'react';
import { useAppSelector } from 'src/app/redux/createAction';
import { PersonMergeMeta } from 'src/pages/MergeToolPage/_BLL/silver_people/types';
import { ConflictsTableSelectable } from 'src/pages/MergeToolPage/ui/SilverMode/PeopleMergeForm/PersonField/ConflictsTableSelectable/ConflictsTableSelectable';
import s from './IndividualDatapointsMC.module.scss';

interface Props {
	personMergeMeta: PersonMergeMeta;
}

export const IndividualDatapointsMC: FC<Props> = props => {
	const {
		personMergeMeta, //
	} = props;

	// * Selectors
	const targetCompany = useAppSelector(state => state.mergeToolCompanySearch.targetCompany);
	const dictionaries = useAppSelector(state => state.metaDictionaries.dictionaries);

	// * Render
	return (
		<div>
			<div className={s.container}>
				{dictionaries && targetCompany && personMergeMeta.conflicts.length > 0 && (
					<>
						<ConflictsTableSelectable
							key={personMergeMeta.name}
							personMergeMeta={personMergeMeta}
							dictionaries={dictionaries}
						/>
					</>
				)}
			</div>
		</div>
	);
};
