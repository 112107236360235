export enum RequestStatus {
	still = 'STILL',
	loading = 'LOADING',
	failed = 'FAILED',
}

export type ResponseStatus = 'Finished' | 'InProgress' | 'Cancelled';

export enum UrlAPI {
	// Authentication
	login = 'login',
	logout = 'logout',
	dictionaries = 'dictionaries',

	// Main search page
	companies = 'companies',

	// Dashboard
	dashboard = 'dashboard',

	// admin upload companies
	company = 'company',
	adminCompany = 'company/admin',

	// admin upload people
	adminPeople = 'people/admin',

	// Database results
	metadata = 'metadata',
	people = 'people',

	// Upload to gold
	toGold = 'updateCompanyToGold',

	// people duplicates
	duplicatePeople = 'duplicatePeople',

	// Merge tool
	mergeToolDiff = 'mergetool/diff',
	mergeToolSave = 'mergetool/save',
	mergeToolMove = 'mergetool/move',
	mergeToolSavePeople = 'mergetool/savepeople',
	mergeToolCompanies = 'mergetool/companies',
	mergeToolTransferChildren = 'mergetool/transferChildren',
	mergeToolCancel ='mergetool/cancel',

	// Documents
	documentsUpload = 'documents/upload',
	documentsUpdate = 'documents/update',
	documentsLookup = 'documents/lookup',
	documentsYears = 'documents/years',
	documentsTypes = 'documents/types',
}

export enum UrlKPI {
	kpiDatabase = 'kpiDatabase',
	kpiCompaniesDatapoints = 'kpiCompaniesDatapoints',
	kpiTotalByRegion = 'kpiTotalByRegion',
	kpiTotalByIndustry = 'kpiTotalByIndustry',
	kpiProductivity24 = 'kpiProductivity24',
	kpiProductivityHistorical = 'kpiProductivityHistorical',
	kpiTimeSpent = 'kpiTimeSpent',

	// In development...
	kpiCompaniesRating = 'kpiCompaniesRating',
	kpiPushedToGold = 'kpiPushedToGold',
	kpiAddedDatapoints = 'kpiAddedDatapoints',
	kpiNewCompanies = 'kpiNewCompanies',
	kpiLastEdited = 'kpiLastEdited',
}
