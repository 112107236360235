import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';
import { RootState } from 'src/app/redux/rootReducer';
import { LogRowColumnFormat, LogRowRowFormat, SingleAdminPayload } from 'src/pages/AdminPage/_BLL/types';
import { actionsUploadToGold } from 'src/pages/DashboardPage/_BLL/uploadToGold/slice';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { ReportingPeriodFormat } from '../ui/ExcelLoader/types';

const NAME = 'content';

const startUpload = createAsyncThunk(
	`${NAME}/startUpload`,
	async (args: { adminCompaniesPayloads: SingleAdminPayload[]; toGold: boolean; type: 'company' | 'person' }, thunkAPI) => {
		const { adminCompaniesPayloads, toGold, type } = args;
		const { dispatch, signal, getState } = thunkAPI;

		for (const adminCompaniesPayload of adminCompaniesPayloads) {
			if (!signal.aborted) {
				await dispatch(
					uploadFile({
						adminCompaniesPayload,
						type,
						signal,
					}),
				);
			}
		}

		// * Bulk upload to gold
		if (toGold && !signal.aborted) {
			const state = getState() as RootState;
			const isRowsFormat = state.admin.isRowFormat;
			const logRows = state.admin.logRows;
			const rows = isRowsFormat ? logRows.rowFormat : logRows.columnFormat;

			for (const row of rows) {
				if (row.errorMessage === null) {
					await dispatch(
						uploadToGold({
							companyId: Number(row.id),
							reportingPeriodId: row.reportingPeriodId,
							signal,
						}),
					);
				}
			}
		}

		// * Ending message
		if (signal.aborted) {
			dispatch(
				actionsNotifications.addNotification({
					type: 'info',
					message: 'Upload canceled',
				}),
			);
		} else {
			dispatch(
				actionsNotifications.addNotification({
					type: 'info',
					message: 'Finished',
				}),
			);
		}
	},
);

const uploadFile = createAsyncThunk(
	`${NAME}/uploadFile`,
	async (args: { adminCompaniesPayload: SingleAdminPayload; type: 'company' | 'person'; signal: AbortSignal }, thunkAPI) => {
		const { adminCompaniesPayload, type, signal } = args;

		const res = await rtkApiRequest.rtkPOSTRequest<{ id: number; reportingPeriodId: number }>({
			url: type === 'company' ? UrlAPI.adminCompany : UrlAPI.adminPeople,
			payload: adminCompaniesPayload,
			thunkAPI,
			signal,
		});

		return res;
	},
);

const uploadToGold = createAsyncThunk(`${NAME}/uploadToGold`, async (args: { companyId: number; reportingPeriodId: number; signal: AbortSignal }, thunkAPI) => {
	const { companyId, reportingPeriodId, signal } = args;
	const { dispatch, rejectWithValue } = thunkAPI;

	if (!signal.aborted) {
		await dispatch(actionsUploadToGold.uploadToGold({ companyId: companyId.toString(), reportingPeriodId, showSuccessNotification: false }))
			.then(res => {
				return res;
			})
			.catch(error => rejectWithValue(error));
	} else {
		return rejectWithValue({ message: 'Upload canceled' });
	}
});

export interface State {
	isRowFormat: boolean;
	reportingPeriodFormat: ReportingPeriodFormat;
	logRows: {
		columnFormat: LogRowColumnFormat[];
		rowFormat: LogRowRowFormat[];
	};
	toGoldCount: number;
	totalRequests: number;
	status: RequestStatus;
}

export const initialState: State = {
	isRowFormat: false,
	reportingPeriodFormat: 'names',
	logRows: {
		columnFormat: [],
		rowFormat: [],
	},
	toGoldCount: 0,
	totalRequests: 0,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setIsRowFormat: (state, action: PayloadAction<boolean>) => {
			state.isRowFormat = action.payload;
		},
		setReportingPeriodFormat: (state, action: PayloadAction<ReportingPeriodFormat>) => {
			state.reportingPeriodFormat = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(startUpload.pending, (state, action) => {
			state.status = RequestStatus.loading;
			state.totalRequests = action.meta.arg.adminCompaniesPayloads.length;
			state.logRows = { columnFormat: [], rowFormat: [] }; // Resets log
			state.toGoldCount = 0;
		});
		builder.addCase(startUpload.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(startUpload.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(uploadFile.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(uploadFile.fulfilled, (state, action) => {
			state.status = RequestStatus.still;

			const res = action.payload;
			const { arg } = action.meta;
			const { adminCompaniesPayload } = arg;

			const { isRowFormat } = state;

			const logItem: LogRowColumnFormat = {
				id: res.id,
				status: 'successfully uploaded' as const,
				errorMessage: null,
				uploadToGold: null,
				reportingPeriodId: res.reportingPeriodId,
			};

			if (isRowFormat) {
				const [dataPointName, value] = Object.entries(adminCompaniesPayload.properties)[0];

				const logItemRow: LogRowRowFormat = {
					...logItem,
					reportingPeriodId: res.reportingPeriodId,
					dataPointName,
					value,
				};
				state.logRows.rowFormat = [...state.logRows.rowFormat, logItemRow];
			} else {
				state.logRows.columnFormat = [...state.logRows.columnFormat, logItem];
			}
		});
		builder.addCase(uploadFile.rejected, (state, action) => {
			state.status = RequestStatus.failed;
			const error: any = action.payload;
			const { arg } = action.meta;
			const { adminCompaniesPayload } = arg;

			const { isRowFormat } = state;

			const logItem: LogRowColumnFormat = {
				id: adminCompaniesPayload.id ?? 'new company',
				reportingPeriodId: adminCompaniesPayload.reportingPeriodId,
				status: 'failed to upload',
				errorMessage: error && error.message ? error.message : 'unknown error',
				uploadToGold: null,
			};

			if (isRowFormat) {
				const [dataPointName, value] = Object.entries(adminCompaniesPayload.properties)[0];

				const logItemRow: LogRowRowFormat = {
					...logItem,
					reportingPeriodId: adminCompaniesPayload.reportingPeriodId,
					dataPointName,
					value,
				};

				state.logRows.rowFormat = [...state.logRows.rowFormat, logItemRow];
			} else {
				state.logRows.columnFormat = [...state.logRows.columnFormat, logItem];
			}
		});

		builder.addCase(uploadToGold.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(uploadToGold.fulfilled, (state, action) => {
			state.status = RequestStatus.still;
			const { companyId, reportingPeriodId } = action.meta.arg;

			const { isRowFormat } = state;

			if (isRowFormat) {
				state.logRows.rowFormat = state.logRows.rowFormat.map(row => {
					if (row.id === companyId && row.reportingPeriodId === reportingPeriodId) {
						return {
							...row,
							uploadToGold: 'successfully uploaded to gold',
						};
					} else {
						return row;
					}
				});
			} else {
				state.logRows.columnFormat = state.logRows.columnFormat.map(row => {
					if (row.id === companyId) {
						return {
							...row,
							uploadToGold: 'successfully uploaded to gold',
						};
					} else {
						return row;
					}
				});
			}

			state.toGoldCount++;
		});
		builder.addCase(uploadToGold.rejected, (state, action) => {
			state.status = RequestStatus.failed;
			const { companyId, reportingPeriodId } = action.meta.arg;
			const error: any = action.payload;

			const { isRowFormat } = state;

			if (isRowFormat) {
				state.logRows.rowFormat = state.logRows.rowFormat.map(row => {
					if (row.id === companyId && row.reportingPeriodId === reportingPeriodId) {
						return {
							...row,
							uploadToGold: error && error.message ? error.message : 'unknown error',
						};
					} else {
						return row;
					}
				});
			} else {
				state.logRows.columnFormat = state.logRows.columnFormat.map(row => {
					if (row.id === companyId) {
						return {
							...row,
							uploadToGold: error && error.message ? error.message : 'unknown error',
						};
					} else {
						return row;
					}
				});
			}

			state.toGoldCount++;
		});
	},
});

export const actionsAdmin = {
	...slice.actions,
	startUpload,
};
