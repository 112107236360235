import { useRef } from 'react';
import { FixedSizeList } from 'react-window';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { mergeToolSilverConflicts } from 'src/pages/MergeToolPage/_BLL/silver_conflicts/slice';
import { RequestStatus } from 'src/shared/api/types';
import { useOnclickAwayHandler } from 'src/shared/hooks/useOnclickAwayHandler';
import { useDebauncedRequest } from 'src/shared/lib/api';
import { StringInput } from 'src/shared/ui/_inputs/StringInput';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import s from './SearchBar.module.scss';
import { SearchBarResult } from './SearchBarResult';

export const SearchBar = () => {
	// * Selectors
	const { search, companies, status } = useAppSelector(state => state.mergeToolCompanySearch);

	// * Actions
	const dispatch = useAppDispatch();
	const { saveSearch, getCompanies, clearCompanies } = mergeToolSilverConflicts;

	const searchZone = useRef(null);
	useOnclickAwayHandler(searchZone, () => dispatch(clearCompanies()));

	useDebauncedRequest({
		action: getCompanies({ search: search }),
		dependencies: [search],
	});

	// * Render
	return (
		<div
			className={s.container}
			ref={searchZone}
		>
			{/* Search bar */}
			<StringInput
				placeholder="search..."
				value={search ?? undefined}
				onChange={e => dispatch(saveSearch(e.currentTarget.value))}
				suffix={status === RequestStatus.loading ? <Preloader size="small" /> : <></>}
			/>

			{/* Search results */}
			{companies.length > 0 ? (
				<div
					className={s.searchResults}
					key={companies.length}
				>
					<FixedSizeList
						itemData={companies}
						height={companies.length >= 4 ? 160 : companies.length * 40}
						width={'100%'}
						itemSize={40}
						itemCount={companies.length}
					>
						{props => (
							<SearchBarResult
								{...props}
								data={companies}
							/>
						)}
					</FixedSizeList>
				</div>
			) : null}
		</div>
	);
};
