import { FC, HTMLAttributes, memo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DICTItem } from 'src/app/redux/slices/metaDictionaries/types';
import { GeneratedField, StatusType } from 'src/pages/DashboardPage/_BLL/dashboard/types/types';
import { useFilledOnce } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/_hooks/useFilledOnce';
import { cancelVerify } from 'src/pages/DashboardPage/ui/DataPointGenerator/fields/_libs/cancelVerify';
import s from './DropDownField.module.scss';
import { Select } from 'src/shared/ui/_inputs/Select';
import { getFieldNameCompany } from 'src/pages/DashboardPage/lib/getFieldNameCompany';

type Props = GeneratedField &
	HTMLAttributes<HTMLDivElement> & {
		dictionarySelectName?: boolean;
	};

export const DropDownField: FC<Props> = memo(props => {
	const {
		namePrefix, //
		propertyName,
		internalName,
		disabled,
		validate,
		fieldDictionary,
		dictionarySelectName,
		customLogic,
	} = props;

	// * Form
	const { setValue, getValues, control } = useFormContext();

	const NAME = getFieldNameCompany({ namePrefix, propertyName, internalName });

	// ! Industry and sector ONLY...
	const dictionaryFilter = (dictionary: DICTItem[]): DICTItem[] => {
		if (NAME === primaryIndustryName) {
			if (primarySectorValue) {
				let filteredDictionary: DICTItem[] = [];

				filteredDictionary = [...filteredDictionary, ...dictionary.filter(item => String(item.id).slice(0, -3) + '000' === primarySectorValue)];

				return filteredDictionary;
			} else {
				return [];
			}
		} else {
			return dictionary;
		}
	};

	// - Primary
	const primarySectorName = 'properties.industryAndSectorPrimary.sector';
	const primaryIndustryName = 'properties.industryAndSectorPrimary.industry';

	const primarySectorValue: string | null = useWatch({
		name: `${primarySectorName}.value`,
	});

	// - Secondary
	const secondarySectorName = 'properties.industryAndSectorSecondary.sector';
	const secondaryIndustryName = 'properties.industryAndSectorSecondary.industry';

	const industrySecondaryValues: string[] | null = useWatch({
		name: `${secondaryIndustryName}.values`,
	});
	// ! ...Industry and sector ONLY

	const handleChange = (fieldName: string, value: string | null) => {
		cancelVerify(namePrefix, propertyName, internalName, setValue, getValues);

		if (value === null) {
			setValue(`${NAME}.status`, StatusType.EMPTY);
		} else {
			setValue(`${NAME}.status`, StatusType.FILLED);
		}

		setValue(fieldName, value);

		// ! Industry and sectors ONLY...
		// When primary sector value changes nullify primary industry.
		if (NAME === primarySectorName) {
			setValue(`${primaryIndustryName}.value`, null);
			setValue(`${primaryIndustryName}.status`, StatusType.EMPTY);

			setValue(`${secondarySectorName}.values`, null);
			setValue(`${secondarySectorName}.status`, StatusType.EMPTY);

			setValue(`${secondaryIndustryName}.values`, null);
			setValue(`${secondaryIndustryName}.status`, StatusType.EMPTY);
		}

		// Removes selected industry in primary from the list of selected industries in secondary.
		if (NAME === primaryIndustryName) {
			const filteredSecondaryIndustries = industrySecondaryValues && industrySecondaryValues.filter(industryId => industryId !== value);

			if (filteredSecondaryIndustries && filteredSecondaryIndustries.length > 0) {
				setValue(`${secondaryIndustryName}.values`, filteredSecondaryIndustries);
			} else {
				setValue(`${secondaryIndustryName}.values`, null);
				setValue(`${secondaryIndustryName}.status`, StatusType.EMPTY);
			}
		}
		// ! ...Industry and sectors ONLY

		// ! People only...
		if (propertyName === 'gender' || propertyName === 'race') {
			let valueToSet = null;

			if (value !== null) {
				valueToSet = propertyName === 'gender' && value === '2' ? 0 : 1; // 0 is Exception for gender other if set is always reported
			}
			setValue(`${NAME}.dataProcessingType`, valueToSet); // set to humanDerived
		}
		// ! ...People only
	};

	// * Custom logic
	// - Filled once
	const { isDisabled } = useFilledOnce(NAME, getValues, 'value', disabled, customLogic);

	// * Conditions
	// ! Industry ONLY ...
	const disableIndustry = NAME === primaryIndustryName && primarySectorValue === null;
	// ! ...

	// * Render
	return (
		<Controller
			name={`${NAME}.value`}
			control={control}
			rules={{ validate: validate }}
			render={({ field, fieldState }) => (
				<Select
					{...field}
					className={s.field}
					showSearch
					placement="bottomLeft"
					value={field.value === null ? '' : field.value}
					filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
					options={
						fieldDictionary && [
							{ value: null, label: 'Unknown' },
							...dictionaryFilter(fieldDictionary).map(dictionary => ({ value: dictionarySelectName ? dictionary.name : String(dictionary.id), label: dictionary.name })),
						]
					}
					onChange={value => {
						handleChange(field.name, value);
					}}
					disabled={isDisabled || disableIndustry}
					error={fieldState.error?.message}
				/>
			)}
		/>
	);
});
