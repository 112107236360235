import { Input, InputProps } from 'antd';
import { FC, useId } from 'react';
import { Label } from '../_common/Label/Label';
import { ErrorMessage } from '../_common/ErrorMessage';

interface Props extends InputProps {
	label?: string;
	error?: string;
}

export const StringInput: FC<Props> = props => {
	const {
		label, //
		error,
		status,
		className,
		...rest
	} = props;

	const id = useId();

	// * Render
	return (
		<div className={className}>
			{label && (
				<Label
					id={id}
					label={label}
				/>
			)}

			<Input
				{...rest}
				id={id}
				status={error ? 'error' : status}
			/>

			{error && <ErrorMessage errorMessage={error} />}
		</div>
	);
};
